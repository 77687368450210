import { NavigationAction } from '@house-id/houseid-types/dist/navigationAction';

/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { getPathWithPropertyIdOrInit } from '../../Auth/navigation/navigation.auth';
import {
  getCreateDocumentPath,
  getDocumentPath,
} from '../modules/Content/modules/Document/navigation.document';
import { getHomePath } from '../navigation/navigation.property';
import useGetCurrentPropertyId from './useGetCurrentPropertyId';
import { getSubscriptionPlansPath } from '../../SubscriptionPlans/navigation/navigation.subscriptionPlans';
import { getRecurringExpensesCategoriesPath } from '../modules/Finances/modules/RecurringExpenses/navigation.recurringExpenses';
import { getFinancesPath } from '../modules/Finances/navigation.finances';

const useGetNavigationAction = () => {
  const { data: propertyId } = useGetCurrentPropertyId();

  const navigationActions: Record<string, (params: any) => string> = {
    [NavigationAction.VIEW_DOCUMENT]: (params: any) => getPathWithPropertyIdOrInit(getDocumentPath, { propertyId, ...params }),
    [NavigationAction.CREATE_DOCUMENT]: (params: any) => getPathWithPropertyIdOrInit(getCreateDocumentPath, { propertyId, ...params }),
    [NavigationAction.VIEW_HOME]: (params: any) => getPathWithPropertyIdOrInit(getHomePath, { propertyId, ...params }),
    [NavigationAction.OPEN_SUBSCRIPTION_PLANS]: (params: any) =>
      getPathWithPropertyIdOrInit(getSubscriptionPlansPath, { propertyId, ...params }),
    [NavigationAction.VIEW_ACCOMMODATION_COST]: (params: any) =>
      getPathWithPropertyIdOrInit(getRecurringExpensesCategoriesPath, { propertyId, ...params }),
    [NavigationAction.VIEW_FINANCE_OVERVIEW]: (params: any) => getPathWithPropertyIdOrInit(getFinancesPath, { propertyId, ...params }),
  };

  return (action: NavigationAction) => navigationActions[action];
};

export default useGetNavigationAction;
